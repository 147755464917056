import "./contact_map.js";
import "./hamburger.js";
import "./animate.js";
import "./coockieconsent.js";

jQuery(document).ready(function($) {
  jQuery("#load-more-work").click(function(event) {
    event.preventDefault();
    console.log('load-more-work" clicked');
    jQuery(".our-work-grid-item:hidden")
      .slice(0, 8)
      .fadeIn("slow");
    if (jQuery(".our-work-grid-item:hidden").length === 0) {
      jQuery("#load-more-work-wrapper").fadeOut("slow");
      jQuery("#load-more-work").fadeOut("slow");
    }
  });

  var otherRangeTitles = $(".other_ranges h5");
  for (var i = 0; i < otherRangeTitles.length; i++) {
    var word = otherRangeTitles[i];
    console.log(word);
    // var text = word.text().trim().split(" ");
    // var last = word.pop();
    // word.html(text.join(" ") + (text.length > 0 ? " <span>" + last + "</span>" : last));
  }

  // Scroll to anchor
  $('a[href*="#"]').on("click", function(e) {
    $("html,body").animate(
      {
        scrollTop: $($(this).attr("href")).offset().top - 50
      },
      1500
    );
    e.preventDefault();
  });
  // Parallax Effect
  function isInViewport(node) {
    var rect = node.getBoundingClientRect();
    return (
      (rect.height > 0 || rect.width > 0) &&
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  var fades = $(
    ".topheadercontent span, .topheadercontent h1, .topheadercontent a, .headerfade"
  );
  $(window).scroll(function() {
    var scrolled = $(window).scrollTop();
    $(".parallaxbg-remove").each(function(index, element) {
      var initY = $(this).offset().top;
      var height = $(this).height();
      var endY = initY + $(this).height();
      var visible = isInViewport(this);
      if (visible) {
        var diff = scrolled - initY;
        var ratio = Math.round((diff / height) * 100);
        $(this).css(
          "background-position",
          "center " + parseInt(-(ratio * 1.2)) + "px"
        );
      }
    });
    $(".parallaxbglong").each(function(index, element) {
      var initY = $(this).offset().top;
      var height = $(this).height();
      var endY = initY + $(this).height();
      var visible = isInViewport(this);
      if (visible) {
        var diff = scrolled - initY;
        var ratio = Math.round((diff / height) * 100);
        $(this).css(
          "background-position",
          "center " + parseInt(-(ratio * 5)) + "px"
        );
      }
    });
    $(".ourprocess-step").each(function(index, element) {
      var initX = $(this).offset().left;
      var width = $(this).width();
      var endX = initX + $(this).width();
      var visible = isInViewport(this);
      if (index % 2) {
        if (visible) {
          var diff = scrolled - initX;
          var ratio = Math.round((diff / width) * 100);
          $(this)
            .find(".sliding-title")
            .css("margin-right", +parseInt(-(ratio * 1.5)) + "px");
        }
      } else {
        if (visible) {
          var diff = scrolled - initX;
          var ratio = Math.round((diff / width) * 100);
          $(this)
            .find(".sliding-title")
            .css("margin-left", +parseInt(-(ratio * 1.5)) + "px");
        }
      }
      var scrollDistance = $(window).scrollTop();

      var scrollTop = $(window).scrollTop(),
        elementOffset = $(this).offset().top,
        distance = elementOffset - scrollTop;
      // console.log($(this), scrollTop, elementOffset, distance )
      // if ($(this).position().top <= scrollDistance) {
      if (distance < 100) {
        $(".ourprocess-navigation a.active").removeClass("active");
        $(".ourprocess-navigation a")
          .eq(index)
          .addClass("active");
      }
    });
    if ($(window).scrollTop() > 150 && $(window).height() > 500) {
      $(fades).addClass("scrolled");
    } else {
      $(fades).removeClass("scrolled");
    }
  });
  // All but the last word to be italic
  $(".part-italic-title").html(function() {
    var text = $(this)
      .text()
      .split(" ");
    var last = text.pop();
    return (
      text.join(" ") +
      (text.length > 0 ? ' <span class="last">' + last + "</span>" : last)
    );
  });
  // Slick sliders
  $(".featuredslider").slick({
    dots: true,
    customPaging: function(slider, i) {
      var thumb = $(slider.$slides[i]).data();
      return "<span>.0" + (i + 1) + "</span>";
    }
  });
  $(".featured-blog-slide").slick({
    dots: true,
    arrows: false,
    draggable: true,
    customPaging: function(slider, i) {
      var thumb = $(slider.$slides[i]).data();
      return "<span>.0" + (i + 1) + "</span>";
    }
  });
  $(".videoslider").slick({
    centerMode: true,
    speed: 1200,
    centerPadding: "60px",
    slidesToShow: 1,
    arrows: true,
    draggable: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    variableWidth: true,
    waitForAnimate: true,
    useTransform: false,
    cssEase: "cubic-bezier(.55,.54,.55,.54)",
    // cssEase: "linear",
    infinite: true
  });
  $(".responsive_featurein").slick({
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 6,
    arrows: false,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    variableWidth: true,
    cssEase: "linear",
    infinite: true
  });
  if (window.matchMedia("(max-width: 608px)").matches) {
    /* the viewport is less than 768 pixels wide */
    $(".our-process-list").slick({
      centerMode: true,
      centerPadding: "40px",
      slidesToShow: 1,
      arrows: false,
      draggable: true,
      dots: false,
      autoplay: false,
      autoplaySpeed: 4000,
      adaptiveHeight: true,
      variableWidth: true,
      cssEase: "linear",
      infinite: true
    });
  }

  $(".videoslider .slick-slide a").bind("click", function(e) {
    e.preventDefault();
  });
  $(".videoslider .slick-slide.slick-active a").unbind("click");
  $(".videoslider").on("afterChange", function() {
    $(".videoslider .slick-slide a").unbind("click");
    $(".videoslider .slick-slide a").bind("click", function(e) {
      e.preventDefault();
    });
    $(".videoslider .slick-slide.slick-active a").unbind("click");
  });
  // Tabs
  $("ul.tabs li").click(function() {
    var tab_id = $(this).attr("data-tab");
    $("ul.tabs li").removeClass("current");
    $(".tab-content").removeClass("current");
    $(this).addClass("current");
    $("#" + tab_id).addClass("current");
  });
  // Sticky sidebar for Our Process
  var $window = $(window);
  var $container = $(".ourprocess-navigation");
  var $main = $(".ourprocesssteps");
  if ($container.length && $main.length) {
    var window_min = 0;
    var window_max = 0;
    var threshold_offset = 50;

    set_limits();
  }
  function set_limits() {
    var max_move =
      $main.offset().top +
      $main.height() -
      $container.height() -
      2 * parseInt($container.css("top"));
    var min_move = $main.offset().top;
    $container.attr("data-min", min_move).attr("data-max", max_move);
    window_min = min_move - threshold_offset;
    window_max = max_move + $container.height() + threshold_offset;
  }
  function window_scroll() {
    if ($window.scrollTop() >= window_min && $window.scrollTop() < window_max) {
      set_limits();
      container_move();
    }
  }
  $window.bind("scroll", window_scroll);
  function container_move() {
    var wst = $window.scrollTop();
    if (
      wst >= $container.attr("data-min") &&
      wst <= $container.attr("data-max")
    ) {
      var margin_top = $window.scrollTop() - $container.attr("data-min");
      $container.css("margin-top", margin_top);
    } else if (wst <= $container.attr("data-min")) {
      $container.css("margin-top", 0);
    } else if (wst > $container.attr("data-max")) {
      $container.css(
        "margin-top",
        $container.attr("data-max") - $container.attr("data-min") + "px"
      );
    }
  }
  container_move();

  $(".site-main")
    .find(".border-hover")
    .each(function() {
      console.log("hover added");

      var $this = $(this);
      $this.append('<div class="line-hover"></div>');
      var w = "100%"; //$this.attr('data-hover') ? $this.attr('data-hover') + '%' : $this.find('.line-hover').siblings('.col-70').outerWidth() + 'px';
      // set un resize uniquement pour les valeur en px
      if ($this.attr("data-hover") === undefined) {
        $window.on("resize", function() {
          w =
            $this.attr("data-hover") !== undefined
              ? $this.attr("data-hover") + "%"
              : $this
                  .find(".line-hover")
                  .siblings(".col-70")
                  .outerWidth() + "px";
        });
      }
      $this.on("mouseenter", function(e) {
        console.log("mouseenter");
        TweenMax.to($this.find(".line-hover"), 0.6, {
          width: w,
          ease: Power2.easeInOut,
          onComplete: function onComplete() {
            $this.find(".line-hover").addClass("right");
          }
        });
      });
      $this.on("mouseleave", function() {
        TweenMax.to($this.find(".line-hover"), 0.4, {
          width: 0,
          ease: Power2.easeInOut,
          onComplete: function onComplete() {
            $this.find(".line-hover").removeClass("right");
          }
        });
      });
    });

  $(".post-single .entry-content")
    .find("a")
    .each(function() {
      console.log("hover added");

      var $this = $(this);
      $this.append('<div class="line-hover"></div>');
      var w = "100%"; //$this.attr('data-hover') ? $this.attr('data-hover') + '%' : $this.find('.line-hover').siblings('.col-70').outerWidth() + 'px';
      // set un resize uniquement pour les valeur en px
      if ($this.attr("data-hover") === undefined) {
        $window.on("resize", function() {
          w =
            $this.attr("data-hover") !== undefined
              ? $this.attr("data-hover") + "%"
              : $this
                  .find(".line-hover")
                  .siblings(".col-70")
                  .outerWidth() + "px";
        });
      }
      $this.on("mouseenter", function(e) {
        console.log("mouseenter");
        TweenMax.to($this.find(".line-hover"), 0.6, {
          width: w,
          ease: Power2.easeInOut,
          onComplete: function onComplete() {
            $this.find(".line-hover").addClass("right");
          }
        });
      });
      $this.on("mouseleave", function() {
        TweenMax.to($this.find(".line-hover"), 0.4, {
          width: 0,
          ease: Power2.easeInOut,
          onComplete: function onComplete() {
            $this.find(".line-hover").removeClass("right");
          }
        });
      });
    });

  $(".policy_item")
    .find("a")
    .each(function() {
      console.log("hover added");

      var $this = $(this);
      $this.append('<div class="line-hover"></div>');
      var w = "100%"; //$this.attr('data-hover') ? $this.attr('data-hover') + '%' : $this.find('.line-hover').siblings('.col-70').outerWidth() + 'px';
      // set un resize uniquement pour les valeur en px
      if ($this.attr("data-hover") === undefined) {
        $window.on("resize", function() {
          w =
            $this.attr("data-hover") !== undefined
              ? $this.attr("data-hover") + "%"
              : $this
                  .find(".line-hover")
                  .siblings(".col-70")
                  .outerWidth() + "px";
        });
      }
      $this.on("mouseenter", function(e) {
        console.log("mouseenter");
        TweenMax.to($this.find(".line-hover"), 0.6, {
          width: w,
          ease: Power2.easeInOut,
          onComplete: function onComplete() {
            $this.find(".line-hover").addClass("right");
          }
        });
      });
      $this.on("mouseleave", function() {
        TweenMax.to($this.find(".line-hover"), 0.4, {
          width: 0,
          ease: Power2.easeInOut,
          onComplete: function onComplete() {
            $this.find(".line-hover").removeClass("right");
          }
        });
      });
    });

  // Our brochures effect
  $(".our-brouchures")
    .find(".brouchure")
    .each(function() {
      console.log("hover added");

      var $this = $(this);
      $this
        .find(".brouchures-detail")
        .append(
          '<div class="line-down"></div><span class="downloadup">download</span>'
        );
      var h = "60px";
      var bot = "40px";
      var botOut = "-20px";
      // set un resize uniquement pour les valeur en px
      if ($this.attr("data-hover") === undefined) {
        $window.on("resize", function() {
          h = "60px";
        });
      }
      $this.on("mouseenter", function(e) {
        console.log("mouseenter");
        TweenMax.to($this.find(".line-down"), 0.6, {
          height: h,
          ease: Power2.easeInOut
        });
        TweenMax.to($this.find(".downloadup"), 0.6, {
          bottom: bot,
          ease: Power2.easeInOut
        });
      });
      $this.on("mouseleave", function() {
        TweenMax.to($this.find(".line-down"), 0.4, {
          height: 0,
          ease: Power2.easeInOut
        });
        TweenMax.to($this.find(".downloadup"), 0.4, {
          bottom: botOut,
          ease: Power2.easeInOut
        });
      });
    });
  // End Our Brochures Effect

  // load more post start
  jQuery(".load-more-cta-btn").click(function() {
    console.log(Date.now());
    var catid = $(this).data("catid");
    var count = $(this).data("count");
    var featured_sluder_id = jQuery("#featured_sluder_id").val();
    var offset = jQuery(".post_items").length; //check if we need to remove the sticky ones
    var data = {
      action: "show_next_posts",
      category: catid,
      offset: offset,
      featured_sluder_id: featured_sluder_id
    };

    var loadPost = jQuery.post(ajaxurl, data, function(response) {
      jQuery("#blog-container-add").append(response);
    });
    loadPost.done(function(data) {
      if (count && count <= jQuery(".post_items").length)
        $(".load-more-cta").fadeOut("slow");
      console.log("done");
    });
  });
  // end load more post start

  $(window).scroll(function() {
    if ($(this).scrollTop() > 1000) {
      $("#scrolltotop").fadeIn();
    } else {
      $("#scrolltotop").fadeOut();
    }
  });
  $("#scrolltotop").click(function() {
    $("html, body").animate({ scrollTop: 0 }, 800);
    return false;
  });

  //Custom select
  // Iterate over each select element

  $(".custom_select").each(function() {
    var $this = $(this),
      numberOfOptions = $(this).children("option").length;

    $this.addClass("select-hidden");
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');

    var $styledSelect = $this.next("div.select-styled");
    $styledSelect.text(
      $this
        .children("option")
        .eq(0)
        .text()
    );

    var $list = $("<ul />", {
      class: "select-options"
    }).insertAfter($styledSelect);

    for (var i = 0; i < numberOfOptions; i++) {
      $("<li />", {
        text: $this
          .children("option")
          .eq(i)
          .text(),
        rel: $this
          .children("option")
          .eq(i)
          .val()
      }).appendTo($list);
    }

    var $listItems = $list.children("li");

    $styledSelect.click(function(e) {
      console.log("$styledSelect");
      e.stopPropagation();
      $("div.select-styled.active")
        .not(this)
        .each(function() {
          $(this)
            .removeClass("active")
            .next("ul.select-options")
            .hide();
        });
      $(this)
        .toggleClass("active")
        .next("ul.select-options")
        .toggle();
    });

    $listItems.click(function(e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass("active");
      $this.val($(this).attr("rel"));
      $list.hide();
      console.log($this.val());
    });

    $(document).click(function() {
      $styledSelect.removeClass("active");
      $list.hide();
    });
  });
  //end custom select

  // end
});
