jQuery(document).ready( function($) {
if ($('.animate-line').length) {
    console.log('change explore position')
    $('.explore-anchor').css('height', '80px')
}
TweenMax.to($('.explore-anchor'), 0.5, { opacity: 1, ease: Power2.easeInOut , onComplete: function onComplete() {
  if ($('.animate-line').length) {
  TweenMax.to($('.explore-anchor-after'), 0.4, { height: 48, ease: Power2.easeInOut , onComplete: function onComplete() {
    TweenMax.to($('.animate-line'), 0.5, { width: '50%', ease: Power2.easeInOut , onComplete: function onComplete() {
      TweenMax.to($('.tab-link'), 0.5, { opacity: 1, ease: Power2.easeInOut });
        } });
      } });
    }
    } });




    $('#secondary_categories').each(function () {

      console.log('secondary_category hover added')

      var $this = $(this);
      var h = $(this).find('.secondary-cat-nav')[0].clientHeight;
      $this.on('mouseenter', function (e) {
        console.log('mouseenter')
          TweenMax.to($this.find('.secondary_category_wrapper'), 0.6, { height: h,  ease: Power2.easeInOut, onComplete: function onComplete() {
                  $this.find('.secondary_category').removeClass('secondary_category_not_visisble');
              } });
      });
      $this.on('mouseleave', function () {
        console.log('mouseleave')
          TweenMax.to($this.find('.secondary_category_wrapper'), 0.6, { height: 0, ease: Power2.easeInOut, onComplete: function onComplete() {
                  $this.find('.secondary_category').addClass('secondary_category_not_visisble');
              } });
      });
    });

    $('#featureds-link').each(function () {

      console.log('featureds-link hover added')

      var $this = $(this);
      var w = 70;
      var h = 30;
      $this.on('mouseenter', function (e) {
        console.log('mouseenter')
          TweenMax.to($this.find('.featured-social'), 0.6, { width: w,   ease: Power2.easeInOut, onComplete: function onComplete() {
                  // $this.find('.secondary_category').removeClass('secondary_category_not_visisble');
              } });
      });
      $this.on('mouseleave', function () {
        console.log('mouseleave')
          TweenMax.to($this.find('.featured-social'), 0.4, { width: 0,  ease: Power2.easeInOut, onComplete: function onComplete() {
                  // $this.find('.secondary_category').addClass('secondary_category_not_visisble');
              } });
      });
    });


})
