jQuery(document).ready( function($) {
// var controlit = $("#burger");

// controlit.click(function() {
//   menuToggle.reversed() ? menuToggle.restart() : menuToggle.reverse();
// });

// const hamburgers = document.querySelectorAll('#hamburger');
// hamburgers.forEach (hamburger => {
//   hamburger.addEventListener('click', (event) => {
//     hamburger.classList.toggle("open");
//   });
// });

// var menuToggle = new TimelineMax({ paused: true, reversed: true });
// menuToggle
//   .set("", { className: "-=closemenu" })
//   .set("", { className: "+=openmenu" })
//   .to(" .top", 0.2, { y: "-9px", transformOrigin: "50% 50%" }, "burg")
//   .to(" .bot", 0.2, { y: "9px", transformOrigin: "50% 50%" }, "burg")
//   .to(" .mid", 0.2, { scale: 0.1, transformOrigin: "50% 50%" }, "burg")
//   .add("rotate")
//   .to(" .top", 0.2, { y: "5" }, "rotate")
//   .to(" .bot", 0.2, { y: "-5" }, "rotate")
//   .to(" .top", 0.2, { rotationZ: 45, transformOrigin: "50% 50%" }, "rotate")
//   .to(" .bot", 0.2, { rotationZ: -45, transformOrigin: "50% 50%" }, "rotate");
// // .set('#burger .mid', {opacity:0})//temp fix for stupid iOS rotate y bug

    var w = '100vw';//$this.attr('data-hover') ? $this.attr('data-hover') + '%' : $this.find('.line-hover').siblings('.col-70').outerWidth() + 'px';
    var menuToggle = $('#hamburger'),
    navWrap = $('.mobile-navigation'),
    menuState = 0;

    menuToggle.on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('open');
        $('html').toggleClass('menuopen');
        if( menuState == 0 ){
          menuState = 1;
          TweenLite.set( navWrap, {xPercent: 0} ); // <-- Resets it's position
          TweenLite.to( navWrap, 1, {left: 0, autoAlpha: 1});
        } else if( menuState == 1 ){
          menuState = 0;
          TweenLite.to( navWrap, 1, {xPercent: 100, autoAlpha: 0} );
        }
    });

    var mobilemenu = $('#menu-mobile-menu').find('.menu-item-has-children').toArray();
    var mobilemenuwraper = $('#menu-mobile-menu').find('.sub-menu')[0]

    var mobileMenuState = 0;
    var h = '100%';
    mobilemenu.forEach(function(el) {
      $(el).on('click',  function(e) {
          // e.preventDefault();
          $(e).toggleClass('submenu_open');
           // console.log(e.srcElement.innerHTML);
          if( mobileMenuState == 0 ){
            mobileMenuState = 1;
            // TweenLite.set( mobilemenuwraper, {height: 0} ); // <-- Resets it's position
            // TweenLite.to( mobilemenuwraper, 1, {height: h});
            $(mobilemenuwraper).toggleClass('submenu_open');
            $(mobilemenuwraper).parent().toggleClass('menu_open');
            TweenMax.to(mobilemenuwraper, 1, { height: h, ease: Power2.easeInOut, onComplete: function onComplete() {

                } });
          } else if( mobileMenuState == 1 && e.srcElement.innerHTML == 'Our Work' ) {
            mobileMenuState = 0;
            $(mobilemenuwraper).toggleClass('submenu_open');
            TweenMax.to(mobilemenuwraper, 1, { height: 0, ease: Power2.easeInOut, onComplete: function onComplete() {
                $(mobilemenuwraper).parent().toggleClass('menu_open');

                } });
          }
      });
      });




})
