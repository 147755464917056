"use strict";

var setCookieForXDays = function(expiresInDays) {
  /**
   * Set cookie for X days for the current domain
   *
   * @param cookieName Cookie name to set
   * @param cookieValue Cookie value to set
   */
  var setCookie = function(cookieName, cookieValue) {
    var date = new Date();
    date.setTime(date.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + "; " + expires + ";  path=/";
  };

  return setCookie;
};

/**
 * Shortcut to set a cookie for one year
 */
var setCookieForOneYear = setCookieForXDays(365);

/**
 * Hide cookie consent, remove body padding and remove event listener
 */
var hide = function() {
  var cookieConsentList = document.querySelectorAll(".cookieConsent");

  for (var i = 0; i < cookieConsentList.length; i++) {
    var cookieConsent = cookieConsentList[i];
    cookieConsent.classList.add("hide");
  }
};

/**
 * When click on dismiss button, hide cookie consent.
 * When click on accept button, set a cookie and hide cookie consent.
 */
function initializeButtonClicks() {
  var cookieConsentAcceptList = document.querySelectorAll(".cookieConsent .accept");

  for (var i = 0; i < cookieConsentAcceptList.length; i++) {
    console.log(i);
    var cookieConsentAccept = cookieConsentAcceptList[i];
    cookieConsentAccept.onclick = function() {
      setCookieForOneYear("_accept_cookies", "true");
      hide('fast');
    };
  }
  var cookieConsentDismissList = document.querySelectorAll(".cookieConsent .dismiss");
  for (var i = 0; i < cookieConsentDismissList.length; i++) {
    var cookieConsentDismiss = cookieConsentDismissList[i];
    if (cookieConsentDismiss) {
      cookieConsentDismiss.onclick = function() {
        hide();
      };
    }
  }
};

function initializeCoockieContainer()  {
  const timeoutID = window.setTimeout(function() {
    let cookieConsentFixedBottom = document.querySelector(".cookieConsent.fixedBottom");
    if (cookieConsentFixedBottom) {
      cookieConsentFixedBottom.classList.add('show');
    }
  }, 2000);
}

function coockieConstentExists() {
  return (document.cookie.indexOf("_accept_cookies=") >= 0);
}

if (!coockieConstentExists()) {
  initializeCoockieContainer();

  setTimeout(function() {
    initializeButtonClicks();
}, 2000);


}
